<script>
import ApiService from "../../Utils/axios";
import Loader from "../../components/xray/Loader";
import VueSlideBar from "vue-slide-bar";
import InputFile from "./InputFile";
import { mapGetters } from "vuex";
import Axios from "axios";
import $ from "jquery";

// import starGray from "../icons/star-gray.svg";
// import laurelGray from "../icons/laurel-gray.svg";
// import laurel from "../icons/laurel.svg";
// import star from "../icons/star.svg";
export default {
  components: { Loader, VueSlideBar, InputFile },
  computed: {
    ...mapGetters({ user: "Auth/getCurrentUser" }),
    assessment_id() {
      return this.$route.query.assessment_id;
    },
    survey_id() {
      return this.$route.query.survey_id;
    },
  },
  data() {
    return {
      // starGray: starGray,
      // star: star,
      // laurel: laurel,
      // laurelGray: laurelGray,

      submitLoader: false,
      done: false,
      currentP: 1,
      chapter: null,
      pageCount: null,
      guide: null,
      answers: {},
      range: [
        { value: "unknown", label: this.$t("buttons.unknown") },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
      ],
      range2: [
        { value: "unknown", label: this.$t("buttons.unknown") },
        { value: "na", label: "N/A" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
      ],
    };
  },
  created() {
    window.onbeforeunload = this.beforeUnload;
  },
  mounted() {
    console.log(this.user);
    document.title = "Evaluation - Auditor";
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    handleYesNo(answer, crit_id) {
      let answers = {};
      for (let key in this.answers) {
        answers[key] = this.answers[key];
      }
      answers[crit_id] = answer;
      this.answers = answers;
    },
    beforeUnload(e) {
      return "Etes vous sur de vouloir quitter sans enregistrer vos reponses?";
    },
    fetchData() {
      new ApiService()
        .get(`/api/survey/${this.survey_id}/start?p=${this.currentP}`)
        .then((res) => {
          this.chapter = res.data.chapter;
          this.pageCount = Number(res.data.nbPages);
          this.guide = res.data.guide;
          this.done = true;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        })
        .catch(() => {
          this.$router.push("/" + this.$i18n.locale + "/survey");
          this.$store.dispatch("Alert/activate", {
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
            type: "danger",
          });
        });
    },
    next(e) {
      e.preventDefault();
      this.submitLoader = true;

      const answers = $(".answer-input");
      let invalidForm = false;
      let y;
      Object.values(answers).forEach((e, i) => {
        if (i < Object.values(answers).length - 2 && $(e).val() === null) {
          invalidForm = true;
          y =
            document.getElementById(e.parentElement.id).getBoundingClientRect()
              .top +
            window.scrollY -
            200;
          let ele = document.getElementById(e.parentElement.id);
          ele.parentElement.parentElement.parentElement.classList.add(
            "answer-flash"
          );

          setTimeout(() => {
            ele.parentElement.parentElement.parentElement.classList.remove(
              "answer-flash"
            );
          }, 3000);
          return;
        }
      });
      if (invalidForm) {
        window.scroll({
          top: y,
          behavior: "smooth",
        });
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: "smooth"
        // });

        this.submitLoader = false;
        this.$store.dispatch("Alert/activate", {
          text: "Veuiller répondre à toutes les questions pour continuer",
          type: "warning",
        });
        return;
      }
      const formData = new FormData(e.currentTarget);
      Axios.post(`${process.env.VUE_APP_API}/api/survey/answer`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${JSON.parse(
            window.localStorage.getItem("token")
          )}`,
        },
      })
        .then((res) => {
          if (this.pageCount === this.currentP) {
            this.$bvModal.show("finish");
          } else {
            this.currentP = this.currentP + 1;
            this.fetchData();
          }
          this.answers = {};
          this.submitLoader = false;
          this.$store.dispatch("Alert/activate", {
            text: "Réponses enregistrées avec succée",
            type: "success",
          });
        })
        .catch((e) => {
          this.submitLoader = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          this.$store.dispatch("Alert/activate", {
            text: "Une erreur c'est produite lors de l'enregistrement des réponses, veuillez réessayer ultérieurement ou contacter l'administration.",
            type: "danger",
          });
          console.log(e.response);
        });
    },
    openModal(id) {
      this.$bvModal.show("modal-" + id);
    },
    openPostModal(id) {
      this.$bvModal.show("modalPost-" + id);
    },
    handleInput(val, crit_id) {
      let answers = this.answers;
      answers[crit_id] = { value: val, feedback: "" };
      this.answers = answers;
    },
    handleInvalid(e) {
      console.log(e);
    },
    routeFinishedEval() {
      if (this.user.roles.includes("MANAGER")) {
        this.$router.push("/" + this.$i18n.locale + "/assessment");
      } else {
        this.$router.push("/" + this.$i18n.locale + "/survey");
      }
    },
  },
};
</script>

<template>
  <div v-if="done">
    <div
      :style="
        user.roles.includes('MANAGER')
          ? 'position: fixed;left: 260px; bottom: 0; z-index: 1000; width: calc(100% - 260px); overflow-x: auto'
          : 'position: fixed; bottom: 0; z-index: 1000; width: 100%; overflow-x: auto'
      "
      class="d-flex bg-white p-2 survey-pagination"
    >
      <div v-for="p of pageCount" :key="{ p }" class="d-flex">
        <button
          v-if="p < currentP"
          class="btn btn-success mx-2 p-1 text-nowrap"
          style="min-width: 35px"
          :key="{ p }"
        >
          <b class="mx-2"> {{ p }} </b> <i class="fas fa-check" />
        </button>
        <button
          v-if="currentP == p"
          class="btn btn-primary mx-2 p-1 text-nowrap"
          style="min-width: 35px"
          :key="{ p }"
        >
          <b class="mx-2"> {{ p }} </b><i class="fas fa-map-marker-alt" />
        </button>
        <button
          v-if="p > currentP"
          class="btn btn-outline-primary mx-2 p-1 text-nowrap"
          style="min-width: 35px"
        >
          <b class="mx-2"> {{ p }} </b>
        </button>
      </div>
    </div>
    <b-container fluid>
      <form @submit="next">
        <input
          type="text"
          class="d-none"
          name="assessment_id"
          :value="assessment_id"
        />
        <input type="text" class="d-none" name="survey_id" :value="survey_id" />
        <iq-card>
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h4 class="card-title text-primary">
                {{ guide.title }}
              </h4>
            </div>
          </div>
          <div
            class="iq-card-body"
            style="
              padding-left: 0 !important;
              padding-right: 0 !important;
              padding-top: 0 !important;
            "
          >
            <div
              class="bg-primary"
              v-if="chapter.parentChapter"
              style="padding: 20px"
            >
              <h5 class="font-weight-bold text-white">
                {{
                  chapter.parentChapter.reference +
                  " " +
                  chapter.parentChapter.title
                }}
              </h5>
              <h5
                class="text-white"
                style="
                  border-left: 2px solid #eeeeee;
                  padding: 20px;
                  margin-left: 10px;
                "
              >
                {{ chapter.reference + " " + chapter.title }}
              </h5>
            </div>
            <div class="bg-primary" v-else style="padding: 20px">
              <h5 class="font-weight-bold text-white">
                {{ chapter.reference + " " + chapter.title }}
              </h5>
            </div>
            <div
              class="criteria"
              style="margin-right: 0; margin-left: 0"
              v-for="crit of chapter.criteria"
              :key="crit.id"
            >
              <div class="d-flex">
                <p style="max-width: 65%" class="col-9 crit-question">
                  <i
                    ><b>{{ crit.refernce }}</b></i
                  >
                  - <b> {{ crit.question }}</b>
                </p>
                <div
                  v-if="crit.subcriteria.length"
                  class="col-3 d-flex justify-content-around"
                >
                  <div v-if="crit.critical" class="text-danger">
                    <i class="fas fa-exclamation-circle" /> {{ $t("critical") }}
                  </div>
                  <div class="text-center mt-3">
                    <span v-if="crit.level == 4">
                      <i class="fas fa-star star-gold star" />
                      <i class="fas fa-star star-gold star" />
                      <i class="fas fa-star star-gold star" />

                      <i class="fas fa-crown star-gold star" />
                    </span>
                    <span v-if="crit.level == 3">
                      <i class="fas fa-star star-gold star" />
                      <i class="fas fa-star star-gold star" />
                      <i class="fas fa-star star-gold star" />

                      <i class="fas fa-crown star" />
                    </span>
                    <span v-if="crit.level == 2">
                      <i class="fas fa-star star-gold star" />
                      <i class="fas fa-star star-gold star" />
                      <i class="fas fa-star star" />

                      <i class="fas fa-crown star" />
                    </span>
                    <span v-if="crit.level == 1">
                      <i class="fas fa-star star-gold star" />
                      <i class="fas fa-star star" />
                      <i class="fas fa-star star" />

                      <i class="fas fa-crown star" />
                    </span>
                  </div>
                </div>
              </div>
              <!-- ======================= NO SUB CRITERIA ========================= -->
              <div class="row" v-if="!crit.subcriteria.length">
                <div class="col-lg-8">
                  <div
                    :id="'crit-' + crit.id"
                    class="answer"
                    v-if="crit.answer === 'yesno'"
                  >
                    <b-button-group class="w-100">
                      <b-button
                        :variant="
                          answers[crit.id] === 'unknown'
                            ? 'secondary'
                            : 'outline-secondary'
                        "
                        @click="handleYesNo('unknown', crit.id)"
                        >{{ $t("buttons.unknown") }}</b-button
                      ><b-button
                        v-if="crit.optional"
                        :variant="
                          answers[crit.id] === 'na'
                            ? 'warning'
                            : 'outline-warning'
                        "
                        @click="handleYesNo('na', crit.id)"
                        >N/A</b-button
                      >
                      <b-button
                        :variant="
                          answers[crit.id] === 'no'
                            ? 'danger'
                            : 'outline-danger'
                        "
                        @click="handleYesNo('no', crit.id)"
                        >{{ $t("buttons.no") }}</b-button
                      >
                      <b-button
                        :variant="
                          answers[crit.id] === 'yes'
                            ? 'success'
                            : 'outline-success'
                        "
                        @click="handleYesNo('yes', crit.id)"
                        >{{ $t("buttons.yes") }}</b-button
                      >
                    </b-button-group>
                    <b-select
                      :name="`answers[${crit.id}]`"
                      :state="!!answers[crit.id]"
                      v-model="answers[crit.id]"
                      class="d-none answer-input"
                    >
                      <b-select-option value="yes">{{
                        $t("buttons.yes")
                      }}</b-select-option>
                      <b-select-option value="no">{{
                        $t("buttons.no")
                      }}</b-select-option>
                      <b-select-option value="na">N/A</b-select-option>
                      <b-select-option value="unknown">{{
                        $t("buttons.unknown")
                      }}</b-select-option>
                    </b-select>
                  </div>
                  <div
                    class="answer"
                    :id="'crit-' + crit.id"
                    v-else-if="crit.answer === 'range'"
                  >
                    <Slider
                      :values="crit.optional ? range2 : range"
                      v-model="answers[crit.id]"
                    ></Slider>

                    <div
                      style="width: 116%"
                      class="row justify-content-between"
                    >
                      <span
                        class="range-label font-weight-bold text-black col-2"
                        >{{ $t("buttons.unknown") }}</span
                      ><span
                        v-if="crit.optional"
                        class="range-label font-weight-bold text-black col-2"
                        >N/A</span
                      ><span
                        class="range-label font-weight-bold text-black col-2"
                        >1</span
                      ><span
                        class="range-label font-weight-bold text-black col-2"
                        >2</span
                      ><span
                        class="range-label font-weight-bold text-black col-2"
                        >3</span
                      ><span
                        class="range-label font-weight-bold text-black col-2"
                        >4</span
                      >
                    </div>
                    <b-select
                      class="d-none answer-input"
                      :name="`answers[${crit.id}]`"
                      :state="!!answers[crit.id]"
                      v-model="answers[crit.id]"
                    >
                      <b-select-option
                        v-for="e of crit.optional ? range2 : range"
                        :value="e.value"
                        :key="e.value"
                        >{{ e.label }}</b-select-option
                      >
                    </b-select>
                  </div>
                  <!--------------------- FILES INPUT MODAL ----------------------->

                  <InputFile
                    v-if="crit.require_files"
                    :survey_id="$route.params.id"
                    :crit="crit"
                  />

                  <b-button
                    class="mt-4"
                    variant="primary"
                    v-if="crit.require_files"
                    @click="openModal(crit.id)"
                    ><i class="fas fa-paperclip"></i>
                    {{ $t("buttons.addFiles") }}</b-button
                  >

                  <!--------------------- FILES INPUT MODAL ----------------------->
                  <b-textarea
                    :name="`feedback[${crit.id}]`"
                    placeholder="feedback"
                    class="mt-3"
                    v-if="user.isauditor"
                  />
                </div>
                <div class="col-lg-4 criteria-options">
                  <div class="d-flex justify-content-around">
                    <div v-if="crit.critical" class="text-danger">
                      <i class="fas fa-exclamation-circle" />
                      {{ $t("critical") }}
                    </div>
                    <div class="d-flex">
                      <span v-if="crit.level == 4">
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />

                        <i class="fas fa-crown star-gold star" />
                      </span>
                      <span v-if="crit.level == 3">
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />

                        <i class="fas fa-crown star" />
                      </span>
                      <span v-if="crit.level == 2">
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star" />

                        <i class="fas fa-crown star" />
                      </span>
                      <span v-if="crit.level == 1">
                        <i class="fas fa-star star-gold star" />
                        <i class="fas fa-star star" />
                        <i class="fas fa-star star" />

                        <i class="fas fa-crown star" />
                      </span>
                    </div>
                  </div>
                  <span v-if="crit.requirements">
                    <div
                      v-b-toggle="'req-' + crit.id"
                      class="d-flex align-items-center b-collapse mt-2"
                    >
                      <span
                        class="badge text-white col-3"
                        style="background: #ff7700"
                        >{{ $t("buttons.requirements") }}</span
                      >
                      <hr class="col-7" />
                      <i class="fas fa-chevron-down"></i>
                    </div>

                    <b-collapse :id="'req-' + crit.id" style="font-size: small"
                      ><nl2br
                        tag="p"
                        :text="crit.requirements"
                        class-name="req" /></b-collapse
                  ></span>
                  <span v-if="crit.explanations"
                    ><div
                      v-b-toggle="'exp-' + crit.id"
                      class="d-flex align-items-center b-collapse mt-2"
                    >
                      <span class="badge bg-primary col-3">{{
                        $t("buttons.explanations")
                      }}</span>
                      <hr class="col-7" />
                      <i class="fas fa-chevron-down"></i>
                    </div>
                    <b-collapse
                      class="exp"
                      :id="'exp-' + crit.id"
                      style="font-size: small"
                    >
                      <nl2br
                        tag="p"
                        :text="crit.explanations"
                        class-name="req"
                      />
                    </b-collapse>
                    <!------------------------------ POST MODAL -------------------------------->
                    <div v-if="crit.posts.length">
                      <b-button
                        @click="openPostModal(crit.id)"
                        variant="primary"
                        >En savoir plus</b-button
                      >

                      <b-modal size="lg" ok-only :id="'modalPost-' + crit.id">
                        <div
                          v-for="post of crit.posts"
                          :key="post.id"
                          v-html="post.content"
                        ></div>
                      </b-modal></div
                  ></span>
                </div>
              </div>
              <!-- ======================= SUB CRITERIA ========================= -->
              <div style="margin-left: 25px" v-else>
                <div
                  v-for="subcrit of crit.subcriteria"
                  class="row sub-crit"
                  :key="subcrit.id"
                >
                  <hr />
                  <div class="col-lg-8">
                    <p class="crit-question">
                      <i
                        ><b>{{ subcrit.refernce }}</b></i
                      >
                      - <b> {{ subcrit.question }}</b>
                    </p>
                    <div
                      :id="'subcrit-' + subcrit.id"
                      class="answer"
                      v-if="subcrit.answer === 'yesno'"
                    >
                      <b-button-group class="w-100">
                        <b-button
                          :variant="
                            answers[subcrit.id] === 'unknown'
                              ? 'secondary'
                              : 'outline-secondary'
                          "
                          @click="handleYesNo('unknown', subcrit.id)"
                          >{{ $t("buttons.unknown") }}</b-button
                        ><b-button
                          v-if="subcrit.optional"
                          :variant="
                            answers[subcrit.id] === 'na'
                              ? 'warning'
                              : 'outline-warning'
                          "
                          @click="handleYesNo('na', subcrit.id)"
                          >N/A</b-button
                        >
                        <b-button
                          :variant="
                            answers[subcrit.id] === 'no'
                              ? 'danger'
                              : 'outline-danger'
                          "
                          @click="handleYesNo('no', subcrit.id)"
                          >{{ $t("buttons.no") }}</b-button
                        >
                        <b-button
                          :variant="
                            answers[subcrit.id] === 'yes'
                              ? 'success'
                              : 'outline-success'
                          "
                          @click="handleYesNo('yes', subcrit.id)"
                          >{{ $t("buttons.yes") }}</b-button
                        >
                      </b-button-group>
                      <b-select
                        :name="`answers[${subcrit.id}]`"
                        :state="!!answers[subcrit.id]"
                        v-model="answers[subcrit.id]"
                        class="d-none answer-input"
                      >
                        <b-select-option value="yes">{{
                          $t("buttons.yes")
                        }}</b-select-option>
                        <b-select-option value="no">{{
                          $t("buttons.no")
                        }}</b-select-option>
                        <b-select-option value="na">N/A</b-select-option>
                        <b-select-option value="unknown">{{
                          $t("buttons.unknown")
                        }}</b-select-option>
                      </b-select>
                    </div>
                    <div
                      :id="'subcrit-' + subcrit.id"
                      class="answer"
                      v-else-if="subcrit.answer === 'range'"
                    >
                      <Slider
                        :values="subcrit.optional ? range2 : range"
                        v-model="answers[subcrit.id]"
                      ></Slider>

                      <div
                        style="width: 116%"
                        class="row justify-content-between"
                      >
                        <span
                          class="range-label font-weight-bold text-black col-2"
                          >{{ $t("buttons.unknown") }}</span
                        ><span
                          v-if="subcrit.optional"
                          class="range-label font-weight-bold text-black col-2"
                          >N/A</span
                        ><span
                          class="range-label font-weight-bold text-black col-2"
                          >1</span
                        ><span
                          class="range-label font-weight-bold text-black col-2"
                          >2</span
                        ><span
                          class="range-label font-weight-bold text-black col-2"
                          >3</span
                        ><span
                          class="range-label font-weight-bold text-black col-2"
                          >4</span
                        >
                      </div>
                      <b-select
                        class="d-none answer-input"
                        :name="`answers[${subcrit.id}]`"
                        :state="!!answers[subcrit.id]"
                        v-model="answers[subcrit.id]"
                      >
                        <b-select-option
                          v-for="e of subcrit.optional ? range2 : range"
                          :value="e.value"
                          :key="e.value"
                          >{{ e.label }}</b-select-option
                        >
                      </b-select>
                    </div>
                    <!--------------------- FILES INPUT MODAL ----------------------->

                    <InputFile
                      v-if="subcrit.require_files"
                      :survey_id="$route.params.id"
                      :crit="subcrit"
                    />

                    <b-button
                      class="mt-4"
                      variant="primary"
                      v-if="subcrit.require_files"
                      @click="openModal(subcrit.id)"
                      ><i class="fas fa-paperclip"></i>
                      {{ $t("buttons.addFiles") }}
                    </b-button>
                    <!--------------------- FILES INPUT MODAL ----------------------->
                  </div>
                  <div class="col-lg-4 criteria-options">
                    <div class="d-flex justify-content-around">
                      <div v-if="subcrit.critical" class="text-danger">
                        <i class="fas fa-exclamation-circle" />
                        {{ $t("critical") }}
                      </div>
                    </div>
                    <span v-if="subcrit.requirements">
                      <div
                        v-b-toggle="'req-' + subcrit.id"
                        class="d-flex align-items-center b-collapse mt-2"
                      >
                        <span
                          class="badge text-white col-3"
                          style="background: #ff7700"
                          >{{ $t("buttons.requirements") }}</span
                        >
                        <hr class="col-7" />
                        <i class="fas fa-chevron-down"></i>
                      </div>

                      <b-collapse
                        :id="'req-' + subcrit.id"
                        style="font-size: small"
                        ><nl2br
                          tag="p"
                          :text="subcrit.requirements"
                          class-name="req" /></b-collapse
                    ></span>
                    <span v-if="subcrit.explanations"
                      ><div
                        v-b-toggle="'exp-' + subcrit.id"
                        class="d-flex align-items-center b-collapse mt-2"
                      >
                        <span class="badge bg-primary col-3">{{
                          $t("buttons.explanations")
                        }}</span>
                        <hr class="col-7" />
                        <i class="fas fa-chevron-down"></i>
                      </div>

                      <b-collapse
                        class="exp"
                        :id="'exp-' + subcrit.id"
                        style="font-size: small"
                        ><nl2br
                          tag="p"
                          :text="subcrit.explanations"
                          class-name="req"
                        />
                      </b-collapse>
                      <!------------------------------ POST MODAL -------------------------------->
                      <div v-if="subcrit.posts.length">
                        <b-button
                          @click="openPostModal(subcrit.id)"
                          variant="primary"
                          >En savoir plus</b-button
                        >
                        <b-modal
                          size="lg"
                          ok-only
                          :id="'modalPost-' + subcrit.id"
                        >
                          <div
                            v-for="post of subcrit.posts"
                            :key="post.id"
                            v-html="post.content"
                          ></div>
                        </b-modal>
                      </div>
                    </span>
                  </div>
                </div>
                <b-textarea
                  :name="`feedback[${crit.id}]`"
                  placeholder="feedback"
                  class="mt-3 w-60"
                  v-if="user.isauditor"
                />
              </div>
            </div>
          </div>
        </iq-card>
        <b-modal
          id="quit"
          title="Attention"
          ok-title="Oui"
          cancel-title="Non"
          @ok="$router.push('/' + $i18n.locale + '/survey')"
        >
          <p class="my-4">
            Voulez vous quitter le questionnaire sans enregistrer les
            modifications, et sans importer les pièces jointes ?
          </p>
        </b-modal>
        <b-modal
          id="finish"
          title="Vous avez terminé cette évaluation"
          ok-title="Oui"
          hide-footer
        >
          <p class="my-4 text-center">
            Vous allez avoir maintenant accès à vos résultats
          </p>
          <b-button class="mt-3" block @click="routeFinishedEval()"
            >Continuer</b-button
          >
        </b-modal>
        <div class="d-flex justify-content-end mb-4">
          <!-- <b-button
          style="width: 48%"
          v-if="currentP - 1 > 0"
          variant="secondary"
          type="button"
          @click="prev()"
          >Précédent</b-button
        > --><b-button
            @click="$bvModal.show('quit')"
            variant="danger"
            type="button"
            class="mx-3"
            >{{ $t("buttons.quit") }}
          </b-button>
          <b-button
            variant="primary"
            type="submit"
            v-if="currentP + 1 <= pageCount"
            ><span v-if="!submitLoader">{{
              $t("buttons.saveAndContinue")
            }}</span>
            <span v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              {{ $t("buttons.saving") }}</span
            >
          </b-button>

          <b-button
            style="width: 20%; background: #ff7700"
            type="submit"
            v-if="currentP + 1 > pageCount"
            ><span v-if="!submitLoader">{{ $t("buttons.finish") }}</span>
            <span v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              {{ $t("buttons.saving") }}</span
            ></b-button
          >
        </div>
      </form>
    </b-container>
  </div>
  <Loader v-else />
</template>

<style>
.b-collapse {
  cursor: pointer;
}

.b-collapse:hover {
  background: #eeeeee77;
}

.criteria {
  border-top: 2px solid #eeeeee;
  padding: 15px;
  color: black;
  min-height: 200px;
}
.criteria:not:first-of-type {
  margin-top: 30px;
}
@media (min-width: 1200px) {
  .answer {
    width: 60%;
  }
}

@media (max-width: 990px) {
  .criteria-options {
    margin-top: 20px;
  }
}

.criteria-options {
  border-left: 1px solid #eeeeee;
}
@keyframes blinker {
  from {
    background: rgba(246, 69, 69, 0);
  }
  to {
    background: rgba(246, 69, 69, 0.733);
  }
}
.answer-flash {
  animation-name: blinker;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.survey-pagination button {
  cursor: default !important;
}
</style>
